import type { BackendChannel } from 'utils/constants'
import { sendRequest } from 'utils/utils'
import type { TenantDetails } from './Infra.type'

const getTestModeFlag = (flag: string) => sessionStorage.getItem(flag)

const setTestModeFlag = (flag: string, value: any) => sessionStorage.setItem(flag, value.toString())

const getTranslationsFlag = () => localStorage.getItem('translate')

// Once we use something besides authIdentifier, update the $pick here
const fetchTenantDetails = (storeFrontUrl: string, tenantId: string, channel: BackendChannel) =>
	sendRequest(false, `${storeFrontUrl}/v1/tenants/${tenantId}?channel=${channel}&%24pick=authIdentifiers`, 'GET') as Promise<TenantDetails>

export default {
	getTestModeFlag,
	setTestModeFlag,
	getTranslationsFlag,
	fetchTenantDetails,
}
