// @ts-nocheck
import { observable, action, runInAction } from 'mobx'
import React from 'react'
import WarningIcon from '@material-ui/icons/Warning'
import Typography from '@material-ui/core/Typography'
import { getDomainByEnv, sendRequest, getAppParams, isMobile, getTranslatedTextByKey } from 'utils/utils'
import { isParamsLocaleValid } from 'utils/validation/validation'
import InfraDependencies from './InfraDependencies'
import _ from 'lodash'
import type { LoginMethods } from './Infra.type'
import { IdentifierType, LoginMode, type AppParams, type AuthIdentifier } from './Infra.type'
import type { LocaleObject } from 'utils/language'
import type { Props as DialogBoxProps } from 'components/common/DialogBox'
import type { SnackBarMessageType } from 'components/common/coupons/types'
import type { BackendChannel } from 'utils/constants'

type DependenciesType = typeof InfraDependencies

class Infra {
	private readonly dependencies: DependenciesType

	/**
	 * Content for the DialogBox
	 *
	 * @type {{}}
	 */
	@observable notification = {}

	@observable popup = {}

	@observable locale: LocaleObject = {}

	@observable triggerCloseNotification = false

	@observable loading = false

	@observable transitionToPageComponent = null

	// TODO: Fill with default values from params.json
	@observable appParams: AppParams = {
		useProductionTheme: false,
		useProductionMenu: false,
	}

	@observable eCommerceFooter: { header: object } = null

	@observable mobileMenu = null

	@observable displayMobileMenu = false

	// eslint-disable-next-line no-undef
	@observable snackbars: { id: string; refEl: React.MutableRefObject<T> }[] = []

	@observable currentSnackBar = {}

	@observable logo: { msg: string } = {}

	@observable isRequestsQueueFree = true

	@observable isRequestPending = false

	@observable testMode = {}

	@observable private authIdentifiers: AuthIdentifier[] = []

	snackbarTimer = null

	snackbarQueue = []

	constructor(dependencies) {
		this.dependencies = dependencies
		runInAction(() => {
			this.resetNotification()
		})
	}

	getLocale() {
		const { l: lang, c: chainId, cust = 'openRest' } = this.appParams

		const queryParams = `chainId=${chainId}&lang=${lang}&type=getLocale&cust=${cust}`

		return sendRequest(false, `${getDomainByEnv()}webFlowAddress?${queryParams}`, 'get', null, null, true, 90000, null, false)
	}

	getAuthIdentifiers = () => this.authIdentifiers

	get loginMethods(): LoginMethods {
		return {
			emailSupported: !!this.authIdentifiers.find((identifier) => identifier.type === IdentifierType.Email),
			phoneSupported: !!this.authIdentifiers.find((identifier) => identifier.type === IdentifierType.Phone),
		}
	}

	get loginMode(): LoginMode {
		const { emailSupported, phoneSupported } = this.loginMethods

		if (!emailSupported && phoneSupported) {
			return LoginMode.PhoneOnly
		}

		if (emailSupported && !phoneSupported) {
			return LoginMode.EmailOnly
		}

		return LoginMode.EmailOrPhone
	}

	@action fetchTenantDetails = async (channel: BackendChannel) => {
		const { authIdentifiers } = await this.dependencies.fetchTenantDetails(this.appParams.storefrontUrl, this.appParams.c, channel)

		this.authIdentifiers = authIdentifiers || []
	}

	@action getParams = () => this.appParams

	@action setGenericPopup = (popup) => {
		const { titleText, subTitleText, children, showClearIcon } = popup
		const _merge = { ...this.popup, ...popup, titleText, subTitleText, children, showClearIcon }
		this.popup = _merge
	}

	@action setNotification = (notification: Omit<DialogBoxProps, 'cancelText' | 'okText'>) => {
		const { cancelText = getTranslatedTextByKey('webviewFlow.cancel'), okText = getTranslatedTextByKey('webviewFlow.ok') } = notification

		if (this.notification?.isPermanent) {
			return
		}

		const _merge = { ...this.notification, ...notification, cancelText, okText }

		this.notification = _merge
	}

	@action openNotification = () => {
		this.notification.open = true
	}

	/**
	 *
	 * @param errorMessage - the bespoke message for the app
	 * @param error - the object thrown by the browser
	 */
	@action setErrorNotification = (errorMessage: React.ReactNode, error?, allowClose? = false, notification? = {}) => {
		const { cancelText = getTranslatedTextByKey('webviewFlow.cancel'), okText = getTranslatedTextByKey('webviewFlow.ok') } = notification
		const _merge = {
			cancelText,
			okText,
			...notification,
			...{
				title: <WarningIcon color="secondary" />,
				message: <Typography variant="caption">{errorMessage}</Typography>,
			},
		}

		if (allowClose) {
			_merge.okAction = () => {
				this.closeNotification()
			}
		}
		_merge.open = true
		this.notification = _merge

		if (error) {
			console.error(error)
		}
	}

	@action closeNotification = (removeStateFromHistory = false, closeEvenIfPermanent = false) => {
		this.resetNotification(closeEvenIfPermanent)
		if (removeStateFromHistory) {
			this.setTriggerNotification(true)
		}
	}

	@action setTriggerNotification = (value) => {
		this.triggerCloseNotification = value
	}

	resetNotification = (closeEvenIfPermanent = false) => {
		if (this.notification?.isPermanent && !closeEvenIfPermanent) {
			return
		}

		this.notification = {}
	}

	@action setLoading = (loading) => {
		this.loading = loading
	}

	@action setTransitionToPageComponent = (pageComponent) => {
		this.transitionToPageComponent = pageComponent
	}

	@action setECommerceFooter = (jsonData) => {
		this.eCommerceFooter = jsonData
	}

	@action setLocale = (locale) => {
		this.locale = locale
	}

	/**
	 * These are used throughout the web-app and loaded from a) params.json OR b) query params
	 *
	 * @param apparams
	 */
	@action setAppParams = (apparams) => {
		this.appParams = apparams
	}

	/**
	 * Set content of the popup menu for the mobile app
	 * @param mobileMenu
	 */
	@action setMobileMenu = (mobileMenu) => {
		this.mobileMenu = mobileMenu
	}

	/**
	 * Function called with no argument: toggle
	 * Function called with a boolean argument: set displayMobileMenu
	 */
	@action toggleMobileMenu = (displayMobileMenu) => {
		this.displayMobileMenu = typeof displayMobileMenu === 'boolean' ? displayMobileMenu : !this.displayMobileMenu
	}

	// eslint-disable-next-line no-undef
	@action addSnackbar = (ids: string[], refEl: React.MutableRefObject<T>) => {
		const arr = ids.map((snackId) => ({ id: snackId, refEl }))
		this.snackbars.push(...arr)
	}

	@action onCloseSnackbar = () => {
		if (!this.snackbarQueue.length) {
			this.currentSnackBar.open = false
			return
		}

		this.currentSnackBar.open = false
		// this timer is for letting the snackbar to be closed smoothly before showing the next one in the queue if any
		setTimeout(() => {
			this.currentSnackBar = { ...this.snackbarQueue.shift(), open: true, onClose: () => this.onCloseSnackbar() }
		}, 200)
	}

	@action showSnackbar = ({
		key,
		snackId,
		message,
		status,
		isAttachedToElement = true,
		duration = 3000,
	}: {
		key?: string
		snackId: string
		message: SnackBarMessageType
		status: string
		isAttachedToElement?: boolean
		duration?: number
	}) => {
		let positionAndSize
		let snackObj = {
			key,
			snackId,
			message,
			status,
			duration,
		}

		const snackBar = this.snackbars.find(({ id }) => id === snackId)
		if (isAttachedToElement && snackBar) {
			snackObj = {
				...snackObj,
				...snackBar,
			}
			positionAndSize = snackObj?.refEl?.current?.getBoundingClientRect()
		} else {
			positionAndSize = {
				width: isMobile() ? window.innerWidth : 440,
				top: window.innerHeight - (isMobile() ? 150 : 100), // show the snackbar n PX from the bottom of the viewport
			}
		}

		if (this.currentSnackBar.open && this.currentSnackBar.snackId !== key) {
			this.snackbarQueue.push({ ...snackObj, positionAndSize, open: false })
			this.currentSnackBar.onClose = this.onCloseSnackbar
		} else {
			this.currentSnackBar = { ...snackObj, positionAndSize, open: true }
		}
	}

	@action clearSnackbars = (ids) => {
		let snackbars = this.snackbars.slice()
		ids.forEach((snackId) => {
			snackbars = snackbars.filter(({ id }) => id !== snackId)
		})
		this.snackbars = snackbars
	}

	@action setLogo = (logo) => {
		this.logo = logo
	}

	@action setIsRequestsQueueFree = (isFree) => {
		this.isRequestsQueueFree = isFree
	}

	@action setIsRequestPending = (isPending) => {
		this.isRequestPending = isPending
	}

	getTestMode = (query) => {
		// Move test flags in query parameters to sessionStorage and handle entering/exiting testMode
		const testMode = {}
		const testFlags = ['force_open', 'test_stores']
		testFlags.forEach((flag) => {
			// NextJS provides the parsed query as an object, elsewhere in the project it is an instance of URLSearchParams
			const value = (query instanceof URLSearchParams ? query.get(flag) : query[flag]) || this.dependencies.getTestModeFlag(flag)
			if (value) {
				testMode[flag] = value === 'true'
				this.dependencies.setTestModeFlag(flag, value === 'true')
			}
		})
		return testMode
	}

	@action setTestMode = (testMode) => {
		this.testMode = testMode
	}

	@observable appTheme = null

	@action setAppTheme = (theme) => {
		this.appTheme = theme
	}

	@observable appFontsUrl = null

	initAppParams = async () => {
		const appParams = await getAppParams()
		this.setAppParams(appParams)
		if (this.appParams?.locale && isParamsLocaleValid(this.appParams?.locale)) {
			this.setLocale(this.appParams?.locale)
		} else {
			const localeRes = await this.getLocale()
			this.setLocale(localeRes)
		}
	}

	@action
	hasFeatureFlag = (flag) => !!this.appParams?.features?.[flag]

	@action
	areTranslationsEnabled = (): boolean => InfraDependencies.getTranslationsFlag() === 'yes'
}

export default new Infra(InfraDependencies)
