import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'next/navigation'

export const useCurrentUrlInfo = () => {
	const router = useRouter()
	const queryParams = useSearchParams()
	const { pathname, asPath } = router

	const [hash, setHash] = useState<string | null>(null)

	const removeHash = () => {
		if (router.asPath.includes('#')) {
			const [pathWithoutHash] = router.asPath.split('#')

			// Push a new state to the history stack without the hash
			window.history.replaceState({}, document.title, pathWithoutHash)

			// Update the router state
			router.push(pathWithoutHash, undefined, { shallow: true })
		}
	}

	const updateHash = useCallback(() => {
		if (router.asPath.includes('#')) {
			const [, newHash] = router.asPath.split('#')
			if (newHash !== hash) {
				setHash(`#${newHash}`)
			}
		} else {
			setHash(null)
		}
	}, [router, hash])

	useEffect(() => {
		updateHash()
	}, [router, pathname, asPath, queryParams, updateHash])

	return {
		asPath,
		pathname,
		query: queryParams,
		dynamicRouteQuery: router.query,
		hash,
		removeHash,
	}
}
