// @ts-nocheck
// TODO: We must replace this context with a mobx store,
//   but let's keep in mind it was done this way in the first place
//   for memory concerns
import type { NextRouter } from 'next/router'
import type { Dispatch, SetStateAction } from 'react'
import { createContext } from 'react'
import type { Store } from 'types/StoreContext'

export const StoreContext = createContext<{ store: Store; setStore: Dispatch<SetStateAction<Store>>; nextJS?: { router: NextRouter } }>()
