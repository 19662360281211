import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import type { Coupon, LocalizedCoupon } from 'types/Coupons'
import { isInStore, OfferType, OrderType } from 'types/Coupons'
import CopyCode from 'components/coupons/CopyCode'
import { cloneObj, codeToLocale, getTranslatedTextByKey } from 'utils/utils'
import TypographyPro from 'themes/TypographyPro'
import CloseIcon from '@material-ui/icons/Close'
import { mediaQueriesBreakpoints } from 'styles/mixins'
import { IconButton, Modal } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { useStores } from 'hooks/useStores'
import CouponsStore from 'mobx/Coupons/store'

import type _User from 'mobx/User'
import type _Account from 'mobx/Account'
import type _Infra from 'mobx/Infra'
import type CouponError from 'mobx/Coupons/errors'
import Alert from 'components/common/Alert'
import type { PrbCoupon } from 'mobx/Coupons'
import ImageNext from 'components/common/ImageNext'
import { getCurrentChannel, getDefaultImgUrl, isCouponForChannel } from 'mobx/CouponFlow/utils'
import CouponButton, { ButtonType, Origin } from './CouponButton'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { buildChannelsErrorText } from 'utils/coupon/couponUtils'
import CouponCodeSkeleton from './CouponCodeSkeleton'

const Main = styled.div`
	background-color: var(--clear);
	width: 100%;
	height: 100%;

	position: fixed;
	inset: 0px;
	margin: auto;
	overflow: auto;

	@media ${mediaQueriesBreakpoints.md} {
		border-radius: var(--borderRadiusFrame);
		width: fit-content;
		height: fit-content;
		max-height: 90%;
	}
`

const Header = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 12px 10px 12px;
	position: sticky;
	background: white;
	top: 0;
	z-index: 10;

	@media ${mediaQueriesBreakpoints.mobile} {
		padding: 12px 20px 12px;
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 10px 10px;

	@media ${mediaQueriesBreakpoints.xs} {
		padding: 0 20px 20px;
	}

	@media ${mediaQueriesBreakpoints.lg} {
		width: 500px;
	}
	gap: 1.75rem;
`
const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;

	width: 100%;
`

const ImageContainer = styled.div`
	position: relative;
	width: 100%;
	aspect-ratio: 191 / 100;
`

const QrContainer = styled.div`
	position: relative;
	width: 45%;
	aspect-ratio: 1 / 1;
`

const Title = styled(TypographyPro).attrs({ variant: 'h4' })`
	color: var(--strokeMenuTitle);
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
`

const Description = styled(TypographyPro).attrs({ variant: 'BodyRegular' })`
	color: var(--strokeMenuTitle);
`

const ExpiryText = styled(TypographyPro).attrs({ variant: 'BodyRegular' })`
	color: var(--strokeMenuTitle);
`

const ExpirationDate = styled(TypographyPro).attrs({ variant: 'BodyRegularHighlighted' })`
	color: var(--strokeMenuTitle);
`

const ExpiryContainer = styled.div`
	display: flex;
	align-items: center;
	color: var(--inactive);
	gap: 0.25rem;
`

const horizontalLineCss = `
	width: 100%;
	min-height: 2px;

	background-image: linear-gradient(to right, var(--disable) 50%, var(--clear) 0%);
	background-position: top;
	background-size: 0.75em 1px;
	background-repeat: repeat;

`

const verticalLineCss = `
	width: 2px;
	height: 100%;
	padding-bottom: calc(75% - 30px);

	background-image: linear-gradient(var(--disable) 50%, var(--clear) 0%);
	background-position: right;
	background-size: 2px 10px;
	background-repeat: repeat-y;
`

const Line = styled.div`
	${horizontalLineCss}// @media ${mediaQueriesBreakpoints.md} {
	// 	${verticalLineCss}
	// }
`

const Section = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
`

const TitleDescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
`

const CodeAndTip = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
`

const ApplyContainer = styled.div`
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`

const CouponModal: React.FC<{ User?: typeof _User; Account?: typeof _Account; Infra?: typeof _Infra }> = ({ User, Account, Infra }) => {
	const { couponsStore, couponFlowStore } = useStores()
	const [errorMessage, setErrorMessage] = useState('')
	const [prbCode, setPrbCode] = useState<PrbCoupon | null>(null)
	const modalRef = useRef<HTMLDivElement | null>(null)

	const { couponModal } = couponsStore

	const localize = useCallback(
		(couponToLocalize: Coupon): LocalizedCoupon =>
			CouponsStore.localizeCoupon(couponToLocalize, codeToLocale[User?.preferredLanguage ?? Infra?.appParams.l ?? 'en']),
		[Infra?.appParams.l, User?.preferredLanguage]
	)

	const coupon = couponModal && localize(couponModal)
	const inStore = coupon && isInStore(coupon)
	const showDetails = !coupon?.flags.requireLogin?.value || Account?.user

	useEffect(() => {
		console.log('modal:', cloneObj(couponModal))
		setErrorMessage('')

		if (!couponModal) {
			setErrorMessage('')
			setPrbCode(null)
			return
		}

		// Check that channel is valid
		if (!inStore && !isCouponForChannel(couponModal.channels)) {
			setErrorMessage(
				`${getTranslatedTextByKey(
					'eCommerce.coupons.modal.couponValidForChannels',
					'This coupon is not valid for {{channel}}. It can only be used on the following channels:',
					// TODO: Create a proper translation function for channels
					{ channel: buildChannelsErrorText({ channels: [getCurrentChannel()] }) }
				)}${buildChannelsErrorText(couponModal)}`
			)
		}

		// Get Code & QR Code for PRB Coupon
		try {
			if (Account?.user && inStore) {
				couponsStore
					.getPrbCode(couponModal.id)
					.then(setPrbCode)
					.catch((error: CouponError) => {
						setPrbCode({ code: '', url: '' })
						setErrorMessage(error.text())
					})

				sendCustomEvent({
					category: 'debug_instore',
					action: 'get_prb_code',
					couponId: couponModal.id,
					user: Account?.user?.user?.email || '',
					userFirstName: Account?.user?.user?.firstName || '',
					time: new Date().toISOString(),
					page_path: window.location.pathname,
				})
			}
			CouponsStore.validateCoupon(couponModal)

			sendCustomEvent({
				category: 'popupView',
				action: 'popup_view',
				page_path: window.location.pathname,
				couponId: coupon?.id,
				user: Account?.user?.user?.email || '',
				userFirstName: Account?.user?.user?.firstName || '',
				time: new Date().toISOString(),
			})
		} catch (error) {
			if (!(couponModal?.flags.applied?.value ?? true)) {
				setErrorMessage((error as CouponError).text({ channel: buildChannelsErrorText({ channels: [getCurrentChannel()] }) }))
			}
		}
	}, [couponModal, Account?.user])

	useEffect(() => {
		/**
		 * MUI tries to optimize focus management for mobile devices
		 * It fails (at least MUI4) to do It when view
		 * has more than one active modal. Such behaviour makes the logging
		 * modal completely un-clickable on mobile devices
		 */
		setTimeout(() => {
			const modalRoot = modalRef?.current as HTMLElement | undefined
			if (!modalRoot?.children) {
				return
			}

			Array.from(modalRoot.children).forEach((node) => {
				if ((node as HTMLElement)?.tabIndex === -1) {
					node.removeAttribute('tabindex')
				}
			})
		})
	}, [coupon])

	if (!coupon) {
		return null
	}

	return (
		<Modal open ref={modalRef}>
			<Main data-testid="modal-coupon-container">
				<Header>
					<IconButton
						data-testid="close-modal"
						onClick={() => {
							couponFlowStore.clearCouponToApply()
							couponsStore.setCouponModal(null)
						}}
						style={{ backgroundColor: 'transparent', padding: 0 }}
					>
						<CloseIcon />
					</IconButton>
				</Header>
				<Container>
					<InfoContainer>
						<ImageContainer data-testid="modal-image-container">
							<ImageNext
								src={coupon.img}
								fill
								alt={coupon.description ?? coupon.title}
								fallbackSrc={getDefaultImgUrl(Infra as typeof _Infra)}
								data-testid="coupon-img"
							/>
						</ImageContainer>
						<Section>
							<TitleDescriptionContainer>
								<Title data-testid="coupon-title">{coupon.title}</Title>
								<Description data-testid="coupon-description">{coupon.description}</Description>
							</TitleDescriptionContainer>
							{coupon.formattedExpiration && (
								<ExpiryContainer>
									<ExpiryText data-testid="modal-expiry-text">
										{getTranslatedTextByKey('eCommerce.coupons.modal.expiry', 'Exp. date:')}
									</ExpiryText>
									<ExpirationDate data-testid="modal-expiry-date">{coupon.formattedExpiration}</ExpirationDate>
								</ExpiryContainer>
							)}
						</Section>

						<ApplyContainer data-testid="modal-apply-container">
							{errorMessage && <Alert variant="error" message={errorMessage} />}
							{!errorMessage && (
								<CouponButton
									coupon={couponModal}
									origin={Origin.MODAL}
									disabled={!!errorMessage}
									onError={(error, actions, disableSnackbar) => {
										if (error.continueFlow && !disableSnackbar) {
											Infra?.showSnackbar({
												status: 'info',
												message: error.text(),
												key: 'coupons-error-snackbar',
												snackId: 'coupons-error-snackbar',
												isAttachedToElement: false,
											})

											actions[ButtonType.APPLY]()
										}
									}}
								/>
							)}
						</ApplyContainer>
					</InfoContainer>

					{!errorMessage && coupon.offerType !== OfferType.ITEM && showDetails && (
						<>
							<Line />
							<CodeAndTip>
								{inStore && (
									<Alert
										variant="info"
										message={getTranslatedTextByKey('eCommerce.coupons.modal.info', 'Show at the cash register')}
									/>
								)}
								<Section>
									{prbCode?.code ?? coupon.code ? (
										<CopyCode code={prbCode?.code ?? coupon.code} />
									) : (
										<CouponCodeSkeleton showCode />
									)}
								</Section>
							</CodeAndTip>
							{inStore &&
								(prbCode ? (
									<QrContainer>
										<ImageNext fallbackSrc="" src={prbCode.url} alt="QR Code" fill />
									</QrContainer>
								) : (
									<CouponCodeSkeleton showQr />
								))}
						</>
					)}
				</Container>
			</Main>
		</Modal>
	)
}

export default inject('User', 'Account', 'Infra')(observer(CouponModal))
