export const SIGN_UP_STEPS = {
	// HOME: 'HOME',
	VERIFY_IDENTIFIER: 'VERIFY_IDENTIFIER',
	EDIT_CODE: 'EDIT_CODE',
	EXTEND_USER_DETAILS: 'EXTEND_USER_DETAILS',
	SIGNED_OUT: 'SIGNED_OUT',
	DELETED: 'DELETED',
}

export const AUTHENTICATION_TYPE = {
	VERIFICATION_CODE: 'verificationCode',
	GUEST: 'guest',
}

export const ECOMMERCE_USER = 'eCommerceUser'
