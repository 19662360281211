// @ts-nocheck
import { observable, action, runInAction, toJS } from 'mobx'
import React from 'react'
import { sendRequest, getTranslatedTextByKey, getDomainByEnv } from 'utils/utils'
import { CONSTANTS } from 'utils/constants'
import queryString from 'query-string'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import storage from 'utils/storage'
import { getThemeUrl } from 'utils/theme/themeManager'
import { THEME_STRATEGY } from 'constants/theme'
import PaymentDependencies from './PaymentDependencies'
import { queueableSendRequest } from 'utils/requestsQueue'
import { sendCustomEvent } from 'utils/analytics/analytics'
import type { ReadonlyURLSearchParams } from 'next/navigation'

class Payment {
	nameToFieldMap = {
		name: 'add_customerName',
		email: 'emailHolder',
		telephone: 'validatePhone',
		deliveryAddress: 'validateAddress',
		addressComments: 'deliveryComments',
	}

	dependencies = null

	@observable user = {}

	@observable comment = ''

	// payment method selected by the user
	@observable paymentMethod = null

	@observable receiveMarketing = false

	// alert legacy: we have this.methods.thirdPartyPayment.url in use
	@observable creditCardUrl = null

	@observable isDeliveryDetailsFormValid = false

	@observable isInvoiceFormValid = false

	@observable isCreditCardFormValid = false

	@observable isFormEditable = false

	@observable deliveryFee = null

	@observable triggerFormValidationUponCheckout = false

	// alert legacy: we have this.methods and this.sortedMethods, we should remove one
	@observable methods = {}

	@observable sortedMethods = []

	// related to TT-1621 these variables were added to solve a race condition between checkout form text fields onBlur to checkout button onSubmit
	// when fired together onSubmit can make the page move to order confirmation before validation has occured which may lead to errors
	@observable onBlurInProgress = false

	@observable isCheckoutSubmitClicked = false

	// @observable currentLoadingFields = new Set()
	currentLoadingFields = observable.set([])

	@observable onClickCallback = null

	constructor(dependencies) {
		this.dependencies = dependencies

		runInAction(() => {
			this.reset()
		})
	}

	@action close = () => {
		this.reset()
	}

	reset = () => {
		this.user = {}
		this.comment = ''
		this.paymentMethod = null
		this.receiveMarketing = false
		this.creditCardUrl = null
		this.isDeliveryDetailsFormValid = false
		this.isInvoiceFormValid = false
		this.isCreditCardFormValid = false
		this.isFormEditable = false
	}

	@action setUserField = (user) => {
		this.user = { ...this.user, ...user }
	}

	@action setComment = (comment) => {
		this.comment = comment
	}

	@action setPaymentMethod = (paymentMethod) => {
		this.paymentMethod = paymentMethod
	}

	@action setReceiveMarketing = (receiveMarketing) => {
		this.receiveMarketing = receiveMarketing
	}

	@action setCreditCardUrl = (creditCardUrl) => {
		this.creditCardUrl = creditCardUrl
	}

	@action setDeliveryDetailsFormValid = (valid) => {
		this.isDeliveryDetailsFormValid = valid
	}

	@action setInvoiceFormValid = (valid: boolean) => {
		this.isInvoiceFormValid = valid
	}

	@action setCreditCardFormValid = (valid) => {
		this.isCreditCardFormValid = valid
	}

	@action setFormEditable = (isEditable) => {
		this.isFormEditable = isEditable
	}

	@action addIsLoadingFieldName = (fieldName) => {
		this.currentLoadingFields.add(fieldName)
	}

	@action removeIsLoadingFieldName = (fieldName) => {
		this.currentLoadingFields.delete(fieldName)
	}

	@action pay = async (data, Cart, Infra, Store, User, isOnlinePayment = false) => {
		const parsed = queryString.parse(location.search)
		parsed.tictuk_listener = parsed.tictuk_listener || parsed.wru

		let jsonResult = null

		switch (this.paymentMethod) {
			case 'card': {
				if (isOnlinePayment) {
					jsonResult = await this.payWithStripe(data, parsed, Infra, Store, Cart, User)
				} else {
					// pay via 3rd party payment gateway
					storage.clearStorage()
					window.location.href = this.creditCardUrl
				}

				break
			}
			case 'cash': {
				jsonResult = await this.payWithCash(Infra, Store, User, Cart)
				break
			}
			case 'creditDebit': {
				break
			}
			default: {
				console.error(`unknown paymentMethod: ${this.paymentMethod}`)
			}
		}

		return jsonResult
	}

	payWithCash = async (Infra, Store, User, Cart) => {
		const parsed = queryString.parse(location.search)
		parsed.tictuk_listener = parsed.tictuk_listener || parsed.wru

		try {
			sendRequest(
				true,
				`${parsed.tictuk_listener}check_field?cust=${parsed.cust}&request=${parsed.request}&field=sendOrder&value=Cash`,
				'get',
				null,
				null,
				true,
				CONSTANTS.PAYMENTS.REQUEST_TIMEOUT_IN_MS
			)

			return true // don't wait for response
		} catch (err) {
			Infra.setLoading(false)
			const title = getTranslatedTextByKey('webviewFlow.orderSendFaildHeader', 'ORDER FAILED!')
			Infra.setNotification({
				title: (
					<>
						<WarningIcon color="secondary" key="1" />
						<span key="2">{title}</span>
					</>
				),
				message: (
					<Typography variant="caption" key={1}>
						{err.message}
					</Typography>
				),
				okAction: () => {
					Infra.closeNotification()
				},
			})

			sendCustomEvent({
				category: 'error',
				action: 'notification',
				label: title,
				message: err?.message || '',
			})

			return false
		}
	}

	payWithStripe = async (data, qs, Infra, Store, Cart, User) => {
		Infra.setNotification({
			title: <span>Confirm Card Payment</span>,
			message: (
				<Typography variant="caption">
					<div key={1}>Order Type: {this.user.getOrderType()}</div>
					<div key={2}>Payment Method: Card</div>
					<div key={3}>Card that ends with: {data.cc}</div>
					<div key={4}>
						Amount: {Store.data.currency}&nbsp;{Cart.serverGrandTotal.toFixed(2)}
					</div>
					<div key={5}>Phone: {this.user.telephone}</div>
				</Typography>
			),
			okText: 'Submit',
			okAction: async () => {
				console.log('submit payment...')
				Infra.closeNotification()
				Infra.setLoading(false)

				const responseSendOrder = await sendRequest(
					true,
					`${qs.tictuk_listener}check_field?cust=${qs.cust}&request=${qs.request}&field=sendOrder&value=cash`,
					'get',
					null,
					null,
					true,
					CONSTANTS.PAYMENTS.REQUEST_TIMEOUT_IN_MS
				)

				if (responseSendOrder.error) {
					const title = getTranslatedTextByKey('webviewFlow.orderSendFaildHeader', 'ORDER FAILED!')
					Infra.setNotification({
						title: (
							<>
								<WarningIcon color="secondary" key={1} />
								<span key={2}>{title}</span>
							</>
						),
						message: <Typography variant="caption">{responseSendOrder.msg}</Typography>,
						okAction: async () => {
							Infra.closeNotification()
						},
					})

					sendCustomEvent({
						category: 'error',
						action: 'notification',
						label: title,
						message: responseSendOrder?.msg || '',
					})
				} else {
					storage.clearStorage()

					// save orderConfirmationData in local storage for confirmation-page
					storage.setStorage({ orderConfirmationData: { cart: Cart } })

					Infra.setNotification({
						title: (
							<>
								<CheckCircleIcon key={1} />
								<span key={2}>{getTranslatedTextByKey('webviewFlow.orderSendSuccessHeader', 'Order Completed Successfully')}</span>
							</>
						),
						message: <Typography variant="caption">{responseSendOrder.msg}</Typography>,
						okAction: () => {
							Infra.closeNotification()
							this.redirectToConfirmationPage(Store)
						},
					})
				}
			},
			cancelAction: () => {
				console.log('cancel payment...')
				Infra.closeNotification()
				Infra.setLoading(false)
			},
		})
	}

	@action removeSavedCard(id, onlinePaymentFirst) {
		// Delete from array of saved cards
		Object.keys(this.methods).forEach((method) => {
			if (this.methods[method].savedCreditCards && this.methods[method].savedCreditCards.length > 0) {
				this.methods[method].savedCreditCards = this.methods[method].savedCreditCards.filter((savedCard) => savedCard.id !== id)
			}
		})
		// delete from methods object
		if (this.methods[id]) {
			delete this.methods[id]
		}
		this.sortMethodsWithOnlinePaymentFirst(!!onlinePaymentFirst)
		if (this.paymentMethod === id) {
			this.paymentMethod = null
		}
	}

	@action initialPaymentMethods = (User) => {
		this.methods = {
			// Alert legacy:
			// We set thirdPartyCreditCard according to the top level payment we
			// receive from the getThirdPartyPayments payments request.
			// Additional payments comes in an array called otherPayments, and they
			// are added to this object with their title as the key.
			// Note this should default to enabled: false since it's url is null
			thirdPartyCreditCard: {
				enabled: User.session.conTxt.submitWithCredit || (User.session.creditAllowed && User.session.creditCollectionType === 'online'),
				title: getTranslatedTextByKey('webviewFlow.onlinePayment', 'Pay Online'),
				iconPath: '/icons/onlinePayment.svg',
				message: `${getTranslatedTextByKey('webviewFlow.onlinePayment')}`,
				payOnline: true,
				serverAPIValue: 'credit',
				subText: `${getTranslatedTextByKey('webviewFlow.onlinePayment')}`,
				url: null,
			},
			cash: {
				enabled: User.session.cashAllowed,
				title: getTranslatedTextByKey('webviewFlow.paymentWithCashUponArrival', 'Pay Upon Arrival'),
				iconPath: '/icons/cashUponArrival.svg',
				message: `${getTranslatedTextByKey('webviewFlow.cashUponArrival')}`,
				payOnline: false,
				serverAPIValue: 'cash',
				subText: `${getTranslatedTextByKey('webviewFlow.paymentWithCashUponArrival')}`,
			},
			creditDebit: {
				enabled: (User.session.creditAllowed && User.session.creditCollectionType !== 'online') || User.session.enableCOD,
				title: getTranslatedTextByKey('webviewFlow.paymentWithCardUponArrival', 'Pay Upon Arrival'),
				iconPath: '/icons/cardUponArrival.svg',
				message: `${getTranslatedTextByKey('webviewFlow.paymentWithCardUponArrival')}`,
				payOnline: false,
				serverAPIValue: 'credit',
				subText: `${getTranslatedTextByKey('webviewFlow.paymentWithCardUponArrival')}`,
			},
		}
	}

	@action updateTopLevelThirdPartyPayment = (creditCard, storeId, parsed, lang, Infra) => {
		if (!creditCard.creditCardURL) {
			return
		}
		this.methods.thirdPartyCreditCard.enabled = true
		this.methods.thirdPartyCreditCard.url = `${creditCard.creditCardURL}&request=${parsed.request}&cust=${parsed.cust}&store=${storeId}&lang=${lang}&isWeb=true`
		if (typeof creditCard.isForceSaveCreditCard === 'boolean') {
			this.methods.thirdPartyCreditCard.isForceSaveCreditCard = creditCard.isForceSaveCreditCard
		}
		if (typeof creditCard.isPayWithSavedCardSupported === 'boolean') {
			this.methods.thirdPartyCreditCard.isPayWithSavedCardSupported = creditCard.isPayWithSavedCardSupported
		}
		this.methods.thirdPartyCreditCard.isEmbeddedIframe = !!creditCard.isEmbeddedIframe
		this.methods.thirdPartyCreditCard.embeddedIframeParams = creditCard?.embeddedIframeParams
		this.methods.thirdPartyCreditCard.isPayWithSavedCardRegularProcess = !!creditCard.isPayWithSavedCardRegularProcess

		if (creditCard.savedCreditCards && creditCard.savedCreditCards.length) {
			this.methods.thirdPartyCreditCard.savedCreditCards = creditCard.savedCreditCards
			const otherPaymentsObject = {}
			for (const savedCreditCard of creditCard.savedCreditCards) {
				otherPaymentsObject[`${savedCreditCard.id}`] = {
					// alert legacy: this code is repeated 3 times in this page, with small differences
					...creditCard,
					url: `${creditCard.creditCardURL}&request=${parsed.request}&cust=${parsed.cust}&store=${storeId}&lang=${lang}&isWeb=true`,
					enabled: true,
					title: creditCard.title,
					iconPath:
						creditCard.iconURL ||
						`${getThemeUrl(THEME_STRATEGY.GENERIC, Infra.appParams.c, Infra.appParams.brand)}/assets/icons/onlinePayment.svg`,
					message: creditCard.title,
					payOnline: true,
					isEmbeddedIframe: !!creditCard.isEmbeddedIframe,
					serverAPIValue: 'NA',
					subText: `${getTranslatedTextByKey('webviewFlow.onlinePayment')}`,
					isThirdParty: true,
					// saved credit card specific
					id: `${savedCreditCard.id}`,
					savedCard: true,
					lastFour: savedCreditCard.lastFour,
				}
			}
			this.methods = { ...otherPaymentsObject, ...this.methods }
		}
	}

	@action disableTopLevelThirdPartyPayment = () => {
		this.methods.thirdPartyCreditCard.enabled = false
	}

	@action addThirdPartyPaymentMethods = (User, Infra, otherPayments, parsed, storeId, lang) => {
		if (!otherPayments || !otherPayments.length) {
			return
		}

		const otherPaymentsObject = {}

		for (const otherPayment of otherPayments) {
			let randomKey
			if (!otherPayment.title) {
				console.warn('Payment title is missing from server configuration')
				randomKey = Math.ceil(Math.random() * 100000).toString()
			}
			otherPaymentsObject[otherPayment.title || randomKey] = {
				// alert legacy: this code is repeated 3 times in this page, with small differences
				...otherPayment,
				url: `${otherPayment.url}&request=${parsed.request}&cust=${parsed.cust}&store=${storeId}&lang=${lang}&isWeb=true`,
				enabled: true,
				title: otherPayment.title,
				iconPath:
					otherPayment.iconURL ||
					`${getThemeUrl(THEME_STRATEGY.GENERIC, Infra.appParams.c, Infra.appParams.brand)}/assets/icons/onlinePayment.svg`,
				message: otherPayment.title,
				payOnline: true,
				isEmbeddedIframe: !!otherPayment.isEmbeddedIframe,
				serverAPIValue: 'NA',
				subText: `${getTranslatedTextByKey('webviewFlow.onlinePayment')}`,
				isThirdParty: true,
			}

			// Saved credit cards come as an array inside a payment option.
			// They are specific for one payment option.
			if (otherPayment.savedCreditCards && otherPayment.savedCreditCards.length) {
				for (const savedCreditCard of otherPayment.savedCreditCards) {
					otherPaymentsObject[`${savedCreditCard.id}`] = {
						// alert legacy: this code is repeated 3 times in this page, with small differences
						...otherPayment,
						url: `${otherPayment.url}&request=${parsed.request}&cust=${parsed.cust}&store=${storeId}&lang=${lang}&isWeb=true`,
						enabled: true,
						title: otherPayment.title,
						iconPath:
							otherPayment.iconURL ||
							`${getThemeUrl(THEME_STRATEGY.GENERIC, Infra.appParams.c, Infra.appParams.brand)}/assets/icons/onlinePayment.svg`,
						message: otherPayment.title,
						payOnline: true,
						serverAPIValue: 'NA',
						subText: `${getTranslatedTextByKey('webviewFlow.onlinePayment')}`,
						isThirdParty: true,
						// saved credit card specific
						id: savedCreditCard.id,
						savedCard: true,
						lastFour: savedCreditCard.lastFour,
					}
				}
			}
		}

		this.methods = { ...this.methods, ...otherPaymentsObject }
	}

	@action getFirstDefaultSavedCardId = () => {
		for (const method in this.methods) {
			if (!this.methods[method].enabled) {
				continue
			}
			if (!this.methods[method].savedCreditCards || this.methods[method].savedCreditCards.length === 0) {
				continue
			}

			for (const savedCreditCard of this.methods[method].savedCreditCards) {
				if (savedCreditCard.default) {
					return `${savedCreditCard.id}`
				}
			}
		}
		return null
	}

	@action setThirdPartyPayments = async ({ lang, parsed, storeId, User, Infra, thirdPartyPayments }) => {
		if (!this.isDeliveryDetailsFormValid) {
			console.info('DELIVERY_DETAILS_FORM_NOT_VALID')
		}

		if (thirdPartyPayments.creditCardURL) {
			this.updateTopLevelThirdPartyPayment(thirdPartyPayments, storeId, parsed, lang, Infra)
		} else {
			this.disableTopLevelThirdPartyPayment()
		}

		if (thirdPartyPayments.otherPayments?.length) {
			this.addThirdPartyPaymentMethods(User, Infra, thirdPartyPayments.otherPayments, parsed, storeId, lang)
			const firstDefaultSavedCardId = this.getFirstDefaultSavedCardId()
			if (firstDefaultSavedCardId) {
				this.setPaymentMethod(firstDefaultSavedCardId)
			}
		}
		this.sortMethodsWithOnlinePaymentFirst(!!User.session.isOnlinePaymentFirst)
	}

	@action sortMethodsWithOnlinePaymentFirst = (isOnlinePaymentFirst) => {
		const sortableMethods = []
		Object.entries(this.methods).forEach(([key, value]) => sortableMethods.push({ [key]: value }))
		sortableMethods.sort((firstEl, secondEl) => {
			if (!firstEl[Object.keys(firstEl)[0]].savedCard && secondEl[Object.keys(secondEl)[0]].savedCard) {
				return 1
			}
			if (firstEl[Object.keys(firstEl)[0]].savedCard && !secondEl[Object.keys(secondEl)[0]].savedCard) {
				return -1
			}
			return isOnlinePaymentFirst
				? secondEl[Object.keys(secondEl)[0]].payOnline - firstEl[Object.keys(firstEl)[0]].payOnline
				: firstEl[Object.keys(firstEl)[0]].payOnline - secondEl[Object.keys(secondEl)[0]].payOnline
		})
		this.sortedMethods = sortableMethods
	}

	@action payViaPaymentGateway = () => {
		window.location.href = this.methods[this.paymentMethod].url
	}

	@action setTriggerFormValidationUponCheckout = (trigger) => {
		this.triggerFormValidationUponCheckout = trigger
	}

	@action setOnBlurInProgress = (status) => {
		this.onBlurInProgress = status
	}

	@action setOnClickCallback = (callback) => {
		this.onClickCallback = callback
	}

	@action setCheckoutSubmitClicked = (status) => {
		this.isCheckoutSubmitClicked = status
	}

	redirectToConfirmationPage = (store) => {
		const { query = {} } = queryString.parseUrl(store?.metaData?.orderConfirmationJSONLink)
		const url = `${window.location.origin}/order-confirmation?token=${query?.token || ''}`
		window.location = url
	}

	@action resetPaymentMethod = () => {
		this.paymentMethod = null
	}

	validateInvoiceFormFieldOnServer = async (
		fieldName: string,
		fieldValue: string,
		formName: string,
		query: ReadonlyURLSearchParams
	): Promise<{ error: boolean; msg: string }> => {
		const ORG_FORM_FIELD_NAME = 'setOrgFormField'

		const tictuktListener = getDomainByEnv()
		this.addIsLoadingFieldName(fieldName)
		const response = await queueableSendRequest(sendRequest)(
			false,
			`${tictuktListener}check_field?cust=${query.get('cust')}&request=${query.get(
				'request'
			)}&field=${ORG_FORM_FIELD_NAME}&value=${encodeURIComponent(JSON.stringify({ formName, fieldName, fieldValue }))}`,
			'get'
		)
		this.removeIsLoadingFieldName(fieldName)

		return response
	}
}

export default new Payment(PaymentDependencies)
