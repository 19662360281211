// @ts-nocheck
import { action, computed, observable } from 'mobx'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import {
	formatLocalDateTime,
	getECommerceDomainByEnv,
	getStoreName,
	getTenantInfo,
	getTranslatedTextByKey,
	initOrUpdateSession,
	isMobileApp,
	sendRequest,
} from 'utils/utils'
import oStorage from 'utils/o-storage'
import { AUTHENTICATION_TYPE, SIGN_UP_STEPS, ECOMMERCE_USER } from 'constants/signUpProcess'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { enableBodyScroll } from 'body-scroll-lock'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import AccountDependencies from './AccountDependencies'
import reactNativeComms from 'utils/reactNativeComms'
import type { UserIdentifier } from './Account.type'
import { IdentifierType } from '../Infra/Infra.type'

class Account {
	dependencies = {}

	constructor(dependencies) {
		this.dependencies = dependencies
	}

	@observable userExists = false

	@observable private identifier: UserIdentifier | null = null

	@observable language = 'ENG'

	@observable signUpStep = null

	@observable user: { user?: { email?: string; firstName?: string; lastName?: string } } | null = null

	@observable signUpPopUp = false

	@observable welcomAlreadyDisplayed = false

	@observable privacyPolicy = null

	@observable termsConditions = null

	@observable personalInfo = {}

	@observable verificationId = ''

	@observable signUpSkipped = false

	@observable userTokens = null

	@observable loggingOut = false

	@observable club = {
		clubName: 'Club',
		date: '2016',
	}

	@observable orderDetails = {
		location: 'Deliver to: 845 15th Street',
		time: '8:00 AM',
	}

	@observable fieldsAttributes = {}

	private onSignupPopupClose = null

	@observable signupTitle = undefined

	@observable signupSubTitle = ''

	@observable signupDisplaySkipSection = false

	@action
	setLoggingOut = (loggingOut) => {
		this.loggingOut = loggingOut
	}

	getIdentifier = () => this.identifier

	/**
	 * checks if the current accessToken which is held by the user is valid for further authorization with the server
	 * jwt's exp holds the number of seconds passed since January 1, 1970, UTC we multiply it by 1000 to align it
	 * with new Date() format in order to check if the expiration date has passed right now.
	 * @param user
	 * @returns {boolean}
	 */
	isTokenValid = (user) => {
		const { accessToken } = user
		const { exp } = jwtDecode(accessToken)
		const now = new Date()
		const expiredDate = new Date(exp * 1000)
		return expiredDate > now
	}

	/**
	 * each account or sign in call to the ecommerce server needs to be accompanied with authorization
	 * this function gets the most updated token and returns the authorization header if the user token is found and valid
	 * if it's not valid we refresh it
	 * if a user is not found no header is being returned
	 * @returns {Promise<{Authorization: string}|null>}
	 */
	getAuthorizationHeader = async (channelType, isDeleteUser = false) => {
		let user = oStorage.get(ECOMMERCE_USER)
		const host = getECommerceDomainByEnv()
		const tenantId = getTenantInfo()

		if (user) {
			const { refreshToken, accessToken } = user
			if (refreshToken || !this.isTokenValid(user)) {
				try {
					const updatedToken = await sendRequest(
						false,
						`${host}/v3/access-tokens`,
						'post',
						{
							authType: 'refreshToken',
							request: Cookies.get('requestParam'),
							channelType,
						},
						{
							'tictuk-tenant-id': tenantId,
							...(refreshToken && { 'Tictuk-Refresh-Token': refreshToken }),
						},
						true,
						90000,
						null,
						true,
						{ withCredentials: true }
					)

					if (refreshToken) {
						delete user.refreshToken
						oStorage.set(ECOMMERCE_USER, user)
					}

					oStorage.set(ECOMMERCE_USER, { ...user, accessToken: updatedToken.accessToken })
					return { Authorization: `Bearer ${updatedToken.accessToken}` }
				} catch (error) {
					oStorage.remove(ECOMMERCE_USER)
					this.user = null
					this.personalInfo = {}
					this.userExists = false
					window.location.redirect = '/home'
				}
			}

			return { Authorization: `Bearer ${accessToken}` }
		}

		user = await this.getAccessTokens(
			{
				authType: AUTHENTICATION_TYPE.GUEST,
			},
			channelType
		)

		return { Authorization: `Bearer ${user.accessToken}` }
	}

	@observable communicationPrefs = {}

	@observable ExpandMoreDetails = {
		// kfcFood: {
		// 	label: 'KFC Food',
		// 	value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		// },
		support: {
			label: 'Support',
			value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		},
		legal: {
			label: 'Legal',
			value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
		},
	}

	getAccessTokens = async (body, channelType) => {
		const host = getECommerceDomainByEnv()
		const tenantId = getTenantInfo()

		return sendRequest(
			false,
			`${host}/v3/access-tokens`,
			'post',
			{ ...body, channelType },
			{
				'tictuk-tenant-id': tenantId,
			},
			true,
			90000,
			null,
			true,
			{ withCredentials: true }
		)
	}

	deleteUserRefreshToken = async () => {
		const host = getECommerceDomainByEnv()
		const tenantId = getTenantInfo()
		const user = this.getUser()
		if (user) {
			try {
				await sendRequest(
					true,
					`${host}/v3/access-tokens/active`,
					'delete',
					null,
					{ 'tictuk-tenant-id': tenantId },
					true,
					90000,
					null,
					true,
					{ withCredentials: true }
				)
			} catch (error) {}
		}
	}

	personalInfoUpdate = async (body, channelType) => {
		const host = getECommerceDomainByEnv()
		const tenantId = getTenantInfo()
		const authHeaders = await this.getAuthorizationHeader(channelType)
		await sendRequest(true, `${host}/v1/tenants/${tenantId}/users/me/accountSettings/personalInfo`, 'put', body, authHeaders)
	}

	accountSettings = async (channelType) => {
		const host = getECommerceDomainByEnv()
		const tenantId = getTenantInfo()
		const authHeaders = await this.getAuthorizationHeader(channelType)
		return sendRequest(false, `${host}/v1/tenants/${tenantId}/users/me/accountSettings`, 'get', null, authHeaders)
	}

	// eslint-disable-next-line class-methods-use-this
	verifyIdentifier = async (type: IdentifierType, value: string, captchaSolution?: string) => {
		const host = getECommerceDomainByEnv()
		const tenantId = getTenantInfo()
		const response = await sendRequest(false, `${host}/v1/tenants/${tenantId}/auth/verifications`, 'post', { type, value, captchaSolution })

		return response
	}

	@action markUserAsSignUp = (store) => {
		const action = this.user.userExists ? 'signin' : 'signup'

		sendCustomEvent({
			category: 'account',
			action,
			label: 'success',
			uuid: this.user?.user?.userConsistentId || '',
			phone_number: this.user?.user?.phone || '',
			email: this.user?.user?.email || '',
			first_name: this.user?.user?.firstName || '',
			last_name: this.user?.user?.lastName || '',
			storeID: store?.data?.id || '',
			storeName: getStoreName(store) || '',
			page_path: window.location.pathname,
			page_location: window.location.href,
		})
	}

	@action setDefaultCountry = (region) => {
		// defaultCountry is an ISO 3166-1 country code, e.g. 'US', 'MX'
		this.defaultCountry = region
	}

	isPushNotificationDeclined = () => this.dependencies.isPushNotificationDeclined()

	getPushNotificationsStatus = () => this.dependencies.getPushNotificationsStatus()

	// This method is called to check the push notification status and open the modal to ask the user to grant them
	@action checkPushNotificationsStatus = (isPushNotificationSupported) => {
		if (!isPushNotificationSupported) {
			return false
		}

		if (!isMobileApp()) {
			return false
		}

		const doesTokenExist = this.getPushNotificationsStatus() === 'accepted'

		if (doesTokenExist) {
			return false
		}

		if (!this.isPushNotificationDeclined()) {
			return false
		}

		return true
	}

	@action checkConsistentIdAndPushNotificationsStatus = async (User, userConsistentId) => {
		const userConsistentIdChanged = User.getUserConsistentId() !== userConsistentId
		User.setUserConsistentId(userConsistentId)

		if (userConsistentIdChanged) {
			const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
			try {
				await initOrUpdateSession({ refObject: { orderType }, shouldRedirectIfError: false })
			} catch {}

			if (this.getPushNotificationsStatus() === 'accepted') {
				reactNativeComms.sendMessage.askPushNotifications()
			}
		}
	}

	@action getUserTokens = () => this.userTokens

	@action verifyCode = async (code, channelType) => {
		try {
			this.userTokens = await this.getAccessTokens({
				authType: AUTHENTICATION_TYPE.VERIFICATION_CODE,
				verificationId: this.verificationId, // Comes from response of the previous request to /phone-verifications
				verificationCode: code, // The code which the user entered
				request: this.dependencies.getSessionId(),
			})

			return this.userTokens
		} catch (error) {
			console.error(error)
			throw error
		}
	}

	@action login = async (User, country, setFullAddress, setTempAddressByOrderType, formattedAddress, store, channelType) => {
		try {
			this.userExists = true
			await this.storeUser(channelType, this.userTokens, User, country, setFullAddress, setTempAddressByOrderType, formattedAddress, store)

			const userConsistentId = this.userTokens.user?.userConsistentId
			if (isMobileApp() && userConsistentId) {
				reactNativeComms.sendMessage.setCUID(userConsistentId, this.userTokens?.user?.email || '')
				this.checkConsistentIdAndPushNotificationsStatus(User, userConsistentId)
			}

			this.markUserAsSignUp(store)
		} catch (error) {
			console.error(error)
			throw error
		}
	}

	// TODO: Check!!! Used only in this file and maybe can be just a regular function and not an action
	@action storeUser = async (channelType, data, User, country, setFullAddress, setTempAddressByOrderType, formattedAddress, store) => {
		const alreadyStoredUser = oStorage.get(ECOMMERCE_USER) || {}
		const updatedUser = { ...alreadyStoredUser, ...data }

		oStorage.set(ECOMMERCE_USER, updatedUser)

		this.user = updatedUser
		await this.getAccountSettings(channelType, User.setSessionField, country, setFullAddress, setTempAddressByOrderType, formattedAddress, store)
	}

	@action storeUserDetails = async (
		channelType,
		fullName,
		email = null,
		phoneNumber = null,
		User = null,
		country = null,
		setFullAddress = null,
		setTempAddressByOrderType = null,
		formattedAddress = null,
		store = null
	) => {
		const fullNameLength = fullName.split(' ').length
		let userInfoForAPI = {
			email: this.identifier?.type === IdentifierType.Email ? this.identifier?.value : email,
			phone: this.identifier?.type === IdentifierType.Phone ? this.identifier?.value : phoneNumber,
		}
		if (fullNameLength === 1) {
			userInfoForAPI = {
				...userInfoForAPI,
				firstName: fullName,
				lastName: '',
			}
		} else {
			userInfoForAPI = {
				...userInfoForAPI,
				firstName: fullName.split(' ').slice(0, -1).join(' '),
				lastName: fullName.split(' ').slice(-1).join(' '),
			}
		}
		const userInfo = {
			...this.user,
			user: {
				...this.user.user,
				...userInfoForAPI,
			},
		}

		if (isMobileApp() && this.user?.user?.userConsistentId) {
			reactNativeComms.sendMessage.setCUID(this.user?.user?.userConsistentId, email || '')
		}

		await this.personalInfoUpdate(userInfoForAPI, channelType)

		await this.storeUser(channelType, userInfo, User, country, setFullAddress, setTempAddressByOrderType, formattedAddress, store)
		this.openSignUpPopUp(false)
	}

	@action skip = (locale, store) => {
		this.signUpSkipped = true

		const _localDateTime = formatLocalDateTime(locale)

		sendCustomEvent({
			category: 'account',
			action: 'signup',
			label: 'skip',
			date: _localDateTime,
			storeID: store?.data?.id || '',
			storeName: getStoreName(store) || '',
		})
	}

	@action moveToSignUpStep = (step) => {
		this.signUpStep = step
	}

	@action getAccountSettings = async (
		channelType,
		setSessionField,
		country,
		setFullAddress,
		setTempAddressByOrderType,
		formattedAddress,
		store
	) => {
		const user = await this.accountSettings(channelType)
		this.personalInfo = user.personalInfo

		if (user.editAccountFormAttributes?.fields) {
			this.fieldsAttributes = user.editAccountFormAttributes.fields.reduce((acc, curr) => {
				acc[curr.name] = curr.attributes
				return acc
			}, {})
		}

		if (this.personalInfo.phone === '0') {
			this.personalInfo.phone = ''
		}

		const lastMostRecentAddressLength = this.personalInfo?.mostRecentAddresses?.length || 0
		// if (lastMostRecentAddressLength > 0 && !formattedAddress) {
		// 	const [lastMostRecentAddress] = this.personalInfo.mostRecentAddresses
		// 	const lastMostRecentAddressFormatted = lastMostRecentAddress.formatted

		// 	if (lastMostRecentAddressFormatted) {
		// 		// the server response provides:
		// 		// "formatted" : "Av. San Diego De Los Padres 768, zona dos extendida, Vista Alegre Maxei, 76074 Santiago de Querétaro, Qro."
		// 		// this needs to be converted into a Google geo code object
		// 		const geoCodeRes = await geoCode(lastMostRecentAddressFormatted, {
		// 			componentRestrictions: { country },
		// 		})

		// 		// 'geoCodeRes' is null ONLY if google map sdk is not loaded
		// 		// it happen on account page, if you refresh the page directly on browser AND
		// 		// - user is already logged
		// 		// - user has already made an order with valid address until checkout page
		// 		// - 'localizedAddress' is empty in localstorage
		// 		// if we navigate from home to account, google map will be loaded
		// 		if (geoCodeRes) {
		// 			// Tomer said the address provided by the KFC user import are for delivery only
		// 			// Daniel said the addresses stored from a chat session are only for delivery as well
		// 			const orderType = ORDER_TYPES.DELIVERY
		// 			setFullAddress(geoCodeRes, orderType, false, true)
		// 			setTempAddressByOrderType(geoCodeRes, 'delivery')

		// 			// apartment info is held in a key called '3' !!
		// 			const apartmentInfo = lastMostRecentAddress['3']

		// 			if (apartmentInfo && apartmentInfo !== '') {
		// 				// set the apartment address info eg 'Apartment 9' on the lastdeliveryComments field so it appears in the Checkout page
		// 				setSessionField('lastdeliveryComments', apartmentInfo)
		// 			}

		// 			sendCustomEvent({
		// 				category: 'account',
		// 				action: 'populate last address',
		// 				label: lastMostRecentAddressFormatted,
		// 				storeID: store?.data?.id || '',
		// 				storeName: getStoreName(store) || '',
		// 			})
		// 		}
		// 	}
		// } else {
		// 	console.log(`this user already has a localizedAddress so no need to set it from the user's login`)
		// 	sendCustomEvent({
		// 		category: 'account',
		// 		action: 'populate last address',
		// 		label: 'not required',
		// 		storeID: store?.data?.id || '',
		// 		storeName: getStoreName(store) || '',
		// 	})
		// }

		const defaultCommunicationPrefs = {
			marketingEmailsOptIn: {
				label: getTranslatedTextByKey('eCommerce.accountSettings.keepMeUpToDate'),
				note: '',
				value: false,
				showToUser: true,
			},
			editorsPickAndCustomersFavoriteOptIn: {
				label: getTranslatedTextByKey('eCommerce.accountSettings.editorsPickAndCustomerFavorite'),
				note: getTranslatedTextByKey('eCommerce.accountSettings.reveiceEditorUpdates'),
				value: false,
				showToUser: false,
			},
			newsAndAccouncementsOptIn: {
				label: getTranslatedTextByKey('eCommerce.accountSettings.newsAndAnnouncements'),
				note: getTranslatedTextByKey('eCommerce.accountSettings.getEmailsAboutSiteImprovements'),
				value: false,
				showToUser: false,
			},
			canSellMyPersonalInfoOptIn: {
				label: getTranslatedTextByKey('eCommerce.accountSettings.doNotSellMyInformation'),
				note: '',
				value: false,
				showToUser: false,
			},
		}

		let commPrefs = {}

		Object.entries(user.communicationPrefs).forEach(([key, value]) => {
			commPrefs = {
				...commPrefs,
				[key]: {
					label: defaultCommunicationPrefs[key].label,
					note: defaultCommunicationPrefs[key].note,
					value,
					showToUser: defaultCommunicationPrefs[key].showToUser,
				},
			}
		})
		this.communicationPrefs = commPrefs
	}

	@action isAuthenticated = () => !!Object.keys(this.getUser()?.user ?? {}).length

	@action onCommunicationPrefsChange = (valueKeyPair) => {
		this.communicationPrefs = {
			...this.communicationPrefs,
			...valueKeyPair,
		}
	}

	@action sendMeCode = async (identifierType: IdentifierType, identifierValue: string, captchaSolution?: string) => {
		const { id } = await this.verifyIdentifier(identifierType, identifierValue, captchaSolution)

		this.identifier = { type: identifierType, value: identifierValue }
		this.verificationId = id
		this.moveToSignUpStep(SIGN_UP_STEPS.EDIT_CODE)
	}

	@action getUser = () => {
		if (!this.user) {
			const storedUser = oStorage.get(ECOMMERCE_USER)
			if (storedUser) {
				this.user = storedUser
				this.userExists = true
			}
		}

		return this.user
	}

	@computed get userName() {
		const _user = this.user
		return _user?.user?.firstName || _user?.user?.lastName || getTranslatedTextByKey('eCommerce.signIn.anonymous')
	}

	@action openSignUpPopUp = async (signUpPopUp, props) => {
		const { onSignupPopupClose, signupTitle, signupSubTitle, signupDisplaySkipSection } = props || {}

		if (onSignupPopupClose) {
			this.onSignupPopupClose = onSignupPopupClose
		}

		if (signUpPopUp) {
			this.moveToSignUpStep(SIGN_UP_STEPS.VERIFY_IDENTIFIER)
		} else {
			const targetElement = document.getElementById('signUpContainer')
			if (targetElement) {
				enableBodyScroll(targetElement)
			}

			if (this.onSignupPopupClose) {
				try {
					const callback = this.onSignupPopupClose

					this.onSignupPopupClose = null
					await callback()
				} catch (error) {
					console.error(error)
				}
			}

			// reset the signup step to stop firing an analytics event
			this.moveToSignUpStep(null)
		}

		this.signUpPopUp = signUpPopUp
		this.identifier = {}
		this.signupTitle = signupTitle
		this.signupSubTitle = signupSubTitle
		this.signupDisplaySkipSection = signupDisplaySkipSection ?? true
	}

	@action saveCommunicationPrefs = async (channelType) => {
		try {
			const authHeaders = await this.getAuthorizationHeader(channelType)
			if (authHeaders) {
				const tenantId = getTenantInfo()
				const host = getECommerceDomainByEnv()
				const body = Object.entries(this.communicationPrefs).reduce((obj, [key, { value }]) => ({ ...obj, [key]: value }), {})
				const saveCommPrefsRes = await sendRequest(
					true,
					`${host}/v1/tenants/${tenantId}/users/me/accountSettings/communicationPrefs`,
					'put',
					body,
					authHeaders
				)
			} else {
				console.error('No authorization for this action')
				window.location.redirect = '/home'
			}
		} catch (error) {
			console.error(error)
		}
	}

	@action leaveClub = () => {
		this.club.clubName = ''
		this.club.date = ''
	}

	@action personalInfoChanged = (valueKeyPair) => {
		this.personalInfo = {
			...this.personalInfo,
			...valueKeyPair,
		}
	}

	@action deleteAccount = async (channelType) => {
		try {
			await this.deleteUserRefreshToken()
			const authHeaders = await this.getAuthorizationHeader(channelType)
			if (authHeaders) {
				const tenantId = getTenantInfo()
				const host = getECommerceDomainByEnv()
				const deleteUserRes = await sendRequest(true, `${host}/v1/tenants/${tenantId}/users/me`, 'delete', null, authHeaders)
				if (!deleteUserRes.error) {
					oStorage.remove(ECOMMERCE_USER)

					this.user = null
					this.personalInfo = {}
					this.userExists = false
				}
			} else {
				console.error('No authorization for this action')
				window.location.redirect = '/home'
			}
		} catch (error) {
			console.error(error)
		}
	}

	@action signedOut = async (setBackdropEnabled, setClickedFeaturedItem) => {
		try {
			await this.deleteUserRefreshToken() // ADD APIS TO SIGNED OUT
			oStorage.remove(ECOMMERCE_USER)

			this.user = null
			this.userExists = false
			this.personalInfo = {}
			setBackdropEnabled(false)
			setClickedFeaturedItem(null)
		} catch (error) {
			console.error(error)
		}
	}

	@action setLanguage = (language) => {
		this.language = language
	}

	@action setTermsConditions = (href) => {
		this.termsConditions = href
	}

	@action setPrivacyPolicy = (href) => {
		this.privacyPolicy = href
	}

	@action setWelcomAlreadyDisplayed = (welcomAlreadyDisplayed) => {
		this.welcomAlreadyDisplayed = welcomAlreadyDisplayed
	}
}

export default new Account(AccountDependencies)
